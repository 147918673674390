import React from "react";
import axios from "axios";
import { Button } from "@mui/material";

const DeleteProduct = ({ productID, onProductDeleted }) => {
  const handleDeleteProduct = async () => {
    console.log(productID);
    try {
      await axios.delete(
        `https://saleswiftapimanagement.azure-api.net/api/products/delete/${productID}`
      );
      onProductDeleted();
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={handleDeleteProduct}
      sx={{ mt: 2, backgroundColor: "#f44336" }}
    >
      Delete Product
    </Button>
  );
};

export default DeleteProduct;
