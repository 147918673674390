import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Drawer,
  Hidden,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import InventoryIcon from "@mui/icons-material/Inventory";
import LogoutIcon from "@mui/icons-material/Logout";
import logo from "../src/assets/SaleSwiftLogoWithoutText.png";

const Sidebar = ({ open, onClose }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("businessID");
    navigate("/login");
  };

  const drawerWidth = 290;

  const drawerContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        bgcolor: "white",
        borderRight: "4px solid #25467B",
        color: "black",
        width: drawerWidth,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 200,
          bgcolor: "white",
          padding: "16px",
          overflow: "hidden",
        }}
      >
        <img
          src={logo}
          alt="SaleSwift Logo"
          style={{
            height: "150px",
            objectFit: "contain",
          }}
        />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto", // Makes the content inside the sidebar scrollable
        }}
      >
        <List>
          <ListItem
            button
            onClick={() => navigate("/dashboard")}
            sx={{
              "&:hover": {
                bgcolor: "#25467B",
                color: "white",
                transition: "transform 0.3s",
                transform: "translateY(-5px)",
                "& .MuiListItemIcon-root": {
                  color: "white",
                },
              },
              transition: "transform 0.3s",
            }}
          >
            <ListItemIcon sx={{ color: "#25467B" }}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem
            button
            onClick={() => navigate("/products")}
            sx={{
              "&:hover": {
                bgcolor: "#25467B",
                color: "white",
                transition: "transform 0.3s",
                transform: "translateY(-5px)",
                "& .MuiListItemIcon-root": {
                  color: "white",
                },
              },
              transition: "transform 0.3s",
            }}
          >
            <ListItemIcon sx={{ color: "#25467B" }}>
              <ShoppingCartIcon />
            </ListItemIcon>
            <ListItemText primary="Products" />
          </ListItem>
          <ListItem
            button
            onClick={() => navigate("/sales")}
            sx={{
              "&:hover": {
                bgcolor: "#25467B",
                color: "white",
                transition: "transform 0.3s",
                transform: "translateY(-5px)",
                "& .MuiListItemIcon-root": {
                  color: "white",
                },
              },
              transition: "transform 0.3s",
            }}
          >
            <ListItemIcon sx={{ color: "#25467B" }}>
              <LocalMallIcon />
            </ListItemIcon>
            <ListItemText primary="Sales" />
          </ListItem>
          <ListItem
            button
            onClick={() => navigate("/stock")}
            sx={{
              "&:hover": {
                bgcolor: "#25467B",
                color: "white",
                transition: "transform 0.3s",
                transform: "translateY(-5px)",
                "& .MuiListItemIcon-root": {
                  color: "white",
                },
              },
              transition: "transform 0.3s",
            }}
          >
            <ListItemIcon sx={{ color: "#25467B" }}>
              <InventoryIcon />
            </ListItemIcon>
            <ListItemText primary="Stock" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem
            button
            onClick={handleLogout}
            sx={{
              "&:hover": {
                bgcolor: "#25467B",
                color: "white",
                transition: "transform 0.3s",
                transform: "translateY(-5px)",
                "& .MuiListItemIcon-root": {
                  color: "white",
                },
              },
              transition: "transform 0.3s",
            }}
          >
            <ListItemIcon sx={{ color: "#25467B" }}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Hidden mdDown>
        <Box
          sx={{
            width: drawerWidth,
            position: "fixed",
            height: "100vh", // Fixed height to viewport
            top: 0, // Positioning it at the top of the viewport
            left: 0, // Aligning to the left
          }}
        >
          {drawerContent}
        </Box>
        <Box
          sx={{
            width: drawerWidth, // Placeholder for the fixed sidebar
            flexShrink: 0,
          }}
        />
      </Hidden>
      <Hidden mdUp>
        <Drawer
          variant="persistent"
          anchor="left"
          open={open}
          onClose={onClose}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              height: "100vh", // Ensures the drawer height matches the viewport height
              position: "fixed",
            },
          }}
        >
          {drawerContent}
        </Drawer>
      </Hidden>
    </Box>
  );
};

export default Sidebar;
