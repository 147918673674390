import React, { useState, useEffect } from "react";
import axios from "axios";
import { TextField, Button, Box } from "@mui/material";

const EditProduct = ({ productID, onProductEdited }) => {
  const [productData, setProductData] = useState({
    productID: productID,
    barcode: "",
    productName: "",
    description: "",
    price: "",
    purchasePrice: "",
    category: "",
    supplier: "",
  });

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(
          `https://saleswiftapimanagement.azure-api.net/api/products/${productID}`
        );
        console.log(response);
        setProductData(response.data);
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchProduct();
  }, [productID]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditProduct = async () => {
    try {
      await axios.put("https://saleswiftapimanagement.azure-api.net/api/products/edit", productData);
      onProductEdited();
    } catch (error) {
      console.error("Error editing product:", error);
    }
  };

  return (
    <Box>
      <TextField
        label="Barcode"
        name="barcode"
        value={productData.barcode}
        onChange={handleChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <TextField
        label="Product Name"
        name="productName"
        value={productData.productName}
        onChange={handleChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <TextField
        label="Description"
        name="description"
        value={productData.description}
        onChange={handleChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <TextField
        label="Price"
        name="price"
        value={productData.price}
        onChange={handleChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <TextField
        label="Purchase Price"
        name="purchasePrice"
        value={productData.purchasePrice}
        onChange={handleChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <TextField
        label="Category"
        name="category"
        value={productData.category}
        onChange={handleChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <TextField
        label="Supplier"
        name="supplier"
        value={productData.supplier}
        onChange={handleChange}
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleEditProduct}
        sx={{ mt: 2 }}
      >
        Edit Product
      </Button>
    </Box>
  );
};

export default EditProduct;
