import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  ButtonGroup,
  Hidden,
} from "@mui/material";
import { Line, Bar } from "react-chartjs-2";
import "chart.js/auto";
import Sidebar from "./Sidebar";
import { format, subDays, startOfMonth } from "date-fns";

const parseDate = (dateString) => {
  const parsedDate = new Date(dateString);
  if (!isNaN(parsedDate)) {
    return parsedDate;
  }
  console.error("Invalid date:", dateString);
  return null;
};

const Dashboard = ({ businessID }) => {
  const [totalSales, setTotalSales] = useState(0);
  const [totalSalesCount, setTotalSalesCount] = useState(0);
  const [weeklySales, setWeeklySales] = useState(0);
  const [monthlySales, setMonthlySales] = useState(0);
  const [recentSales, setRecentSales] = useState([]);
  const [salesData, setSalesData] = useState({
    labels: [],
    datasets: [
      {
        label: "Sales",
        data: [],
        fill: false,
        borderColor: "#25467B",
        tension: 0.1,
      },
    ],
  });
  const [barChartData, setBarChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Monthly Sales",
        data: [],
        backgroundColor: "#25467B",
      },
    ],
  });
  const [filter, setFilter] = useState("lifetime");

  const fetchData = async () => {
    try {
      const salesResponse = await axios.get(
        `https://saleswiftapimanagement.azure-api.net/api/business/${businessID}/sales`
      );
      const salesData = salesResponse.data;

      const totalAmount = salesData.reduce(
        (acc, sale) => acc + (sale.finalAmount || 0),
        0
      );

      const oneWeekAgo = subDays(new Date(), 7);
      const weeklyAmount = salesData
        .filter((sale) => {
          const saleDate = parseDate(sale.saleDate);
          if (!saleDate) return false;
          return saleDate >= oneWeekAgo;
        })
        .reduce((acc, sale) => acc + (sale.finalAmount || 0), 0);

      const currentMonth = new Date().getMonth();
      const monthlyAmount = salesData
        .filter((sale) => {
          const saleDate = parseDate(sale.saleDate);
          if (!saleDate) return false;
          return (
            saleDate.getMonth() === currentMonth &&
            saleDate.getFullYear() === new Date().getFullYear()
          );
        })
        .reduce((acc, sale) => acc + (sale.finalAmount || 0), 0);

      const recentSalesResponse = await axios.get(
        `https://saleswiftapimanagement.azure-api.net/api/business/${businessID}/recent-sales`
      );
      const recentSalesData = recentSalesResponse.data;

      setTotalSales(totalAmount);
      setTotalSalesCount(salesData.length);
      setWeeklySales(weeklyAmount);
      setMonthlySales(monthlyAmount);
      setRecentSales(recentSalesData);

      updateBarChartData(salesData);
      updateChartData(salesData, filter); // Call to update chart data based on filter
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateChartData = (salesData, filter) => {
    let filteredSalesData = salesData;
    if (filter === "week") {
      const oneWeekAgo = subDays(new Date(), 7);
      filteredSalesData = salesData.filter(
        (sale) => parseDate(sale.saleDate) >= oneWeekAgo
      );
    } else if (filter === "month") {
      const startOfCurrentMonth = startOfMonth(new Date());
      filteredSalesData = salesData.filter(
        (sale) => parseDate(sale.saleDate) >= startOfCurrentMonth
      );
    }

    const salesDates = filteredSalesData
      .map((sale) => {
        const date = parseDate(sale.saleDate);
        return date ? format(date, "MMM dd, yyyy") : "Invalid Date";
      })
      .reverse();
    const salesAmounts = filteredSalesData
      .map((sale) => sale.finalAmount || 0)
      .reverse();

    setSalesData({
      labels: salesDates,
      datasets: [
        {
          label: "Sales",
          data: salesAmounts,
          fill: false,
          borderColor: "#25467B",
          tension: 0.1,
        },
      ],
    });
  };

  const updateBarChartData = (salesData) => {
    const salesByMonth = {};

    salesData.forEach((sale) => {
      const saleDate = parseDate(sale.saleDate);
      if (saleDate) {
        const month = format(saleDate, "MMM yyyy");
        if (!salesByMonth[month]) {
          salesByMonth[month] = 0;
        }
        salesByMonth[month] += sale.finalAmount || 0;
      }
    });

    const months = Object.keys(salesByMonth).reverse();
    const amounts = months.map((month) => salesByMonth[month]);

    setBarChartData({
      labels: months,
      datasets: [
        {
          label: "Monthly Sales",
          data: amounts,
          backgroundColor: "#25467B",
        },
      ],
    });
  };

  const chartOptions = {
    scales: {
      x: {
        ticks: {
          display: false,
        },
      },
    },
  };

  useEffect(() => {
    fetchData();
  }, [businessID]);

  useEffect(() => {
    const updateSalesData = async () => {
      const salesResponse = await axios.get(
        `https://saleswiftapimanagement.azure-api.net/api/business/${businessID}/sales`
      );
      const salesData = salesResponse.data;
      updateChartData(salesData, filter);
    };
    updateSalesData();
  }, [filter, businessID]);

  return (
    <Box sx={{ display: "flex" }}>
      <Hidden mdDown>
        <Sidebar />
      </Hidden>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: 3,
          ml: { xs: 0, md: 4 }, // Add margin-left for medium screens and up
        }}
      >
        <Container>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ color: "#25467B", fontWeight: 900 }}
          >
            Dashboard
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography variant="h6">Total Sales</Typography>
                  <Typography
                    variant="h4"
                    sx={{ color: "#25467B", fontWeight: 400 }}
                  >
                    R{totalSales.toFixed(2)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography variant="h6">Total Sales Count</Typography>
                  <Typography
                    variant="h4"
                    sx={{ color: "#25467B", fontWeight: 400 }}
                  >
                    {totalSalesCount}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography variant="h6">Weekly Sales</Typography>
                  <Typography
                    variant="h4"
                    sx={{ color: "#25467B", fontWeight: 400 }}
                  >
                    R{weeklySales.toFixed(2)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography variant="h6">Monthly Sales</Typography>
                  <Typography
                    variant="h4"
                    sx={{ color: "#25467B", fontWeight: 400 }}
                  >
                    R{monthlySales.toFixed(2)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <ButtonGroup sx={{ mt: 3 }}>
            <Button
              variant={filter === "lifetime" ? "contained" : "outlined"}
              onClick={() => setFilter("lifetime")}
            >
              Lifetime
            </Button>
            <Button
              variant={filter === "month" ? "contained" : "outlined"}
              onClick={() => setFilter("month")}
            >
              This Month
            </Button>
            <Button
              variant={filter === "week" ? "contained" : "outlined"}
              onClick={() => setFilter("week")}
            >
              This Week
            </Button>
          </ButtonGroup>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ mt: 4, color: "#25467B", fontWeight: 900 }}
          >
            Sales Chart
          </Typography>
          <Line data={salesData} options={chartOptions} />
          <Typography
            variant="h5"
            gutterBottom
            sx={{ mt: 4, color: "#25467B", fontWeight: 900 }}
          >
            Recent Sales
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Sale ID</TableCell>
                  <TableCell>Sale Date</TableCell>
                  <TableCell>Close Time</TableCell>
                  <TableCell>Final Amount</TableCell>
                  <TableCell>Employee Name</TableCell>
                  <TableCell>Site Location</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recentSales.map((sale) => (
                  <TableRow key={sale.saleID}>
                    <TableCell>{sale.saleID}</TableCell>
                    <TableCell>
                      {format(parseDate(sale.saleDate), "MMM dd, yyyy hh:mm a")}
                    </TableCell>
                    <TableCell>
                      {format(
                        parseDate(sale.closeTime),
                        "MMM dd, yyyy hh:mm a"
                      )}
                    </TableCell>
                    <TableCell>{sale.finalAmount}</TableCell>
                    <TableCell>{sale.employeeName}</TableCell>
                    <TableCell>{sale.siteLocation}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ mt: 4, color: "#25467B", fontWeight: 900 }}
          >
            Monthly Sales
          </Typography>
          <Bar data={barChartData} options={chartOptions} />
        </Container>
      </Box>
    </Box>
  );
};
export default Dashboard;
