import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Box,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";

const AddProduct = ({ businessID, onProductAdded }) => {
  const [productData, setProductData] = useState({
    barcode: "",
    productName: "",
    description: "",
    price: "",
    purchasePrice: "",
    stock: "",
    category: "",
    supplier: "",
    siteID: "",
  });
  const [sites, setSites] = useState([]);

  useEffect(() => {
    const fetchSites = async () => {
      try {
        const response = await axios.get(
          `https://saleswiftapimanagement.azure-api.net/api/business/${businessID}/sites`
        );
        console.log(response.data);
        setSites(response.data);
      } catch (error) {
        console.error("Error fetching sites:", error);
      }
    };

    fetchSites();
  }, [businessID]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (e) => {
    setProductData((prevData) => ({
      ...prevData,
      siteID: e.target.value,
    }));
  };

  const handleAddProduct = async () => {
    try {
      await axios.post("https://saleswiftapimanagement.azure-api.net/api/products/add", productData);
      onProductAdded();
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  return (
    <Box>
      <TextField
        label="Barcode"
        name="barcode"
        value={productData.barcode}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Product Name"
        name="productName"
        value={productData.productName}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Description"
        name="description"
        value={productData.description}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Price"
        name="price"
        value={productData.price}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Purchase Price"
        name="purchasePrice"
        value={productData.purchasePrice}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Stock"
        name="stock"
        value={productData.stock}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Category"
        name="category"
        value={productData.category}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Supplier"
        name="supplier"
        value={productData.supplier}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <FormControl fullWidth margin="normal">
        <InputLabel id="site-select-label">Site</InputLabel>
        <Select
          labelId="site-select-label"
          value={productData.siteID}
          onChange={handleSelectChange}
        >
          {sites.map((site) => (
            <MenuItem key={site.siteID} value={site.siteID}>
              {site.siteName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddProduct}
        sx={{ mt: 2 }}
      >
        Add Product
      </Button>
    </Box>
  );
};

export default AddProduct;
